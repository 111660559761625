/*
GENERAL
*/

body, html {
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif!important;
}

[ng-cloak]
{
    display: none !important;
}

.page-without-navigation {
    padding-top: 40px;
}

.page-with-navigation {
    padding-top: 60px;
}

/*------HELPERS-------*/

.margin-left {
    margin-left: 20px;
}

.margin-left-small {
    margin-left: 10px;
}

.margin-right {
    margin-right: 20px;
}

.margin-right-small {
    margin-right: 10px;
}

.icon-left {
    margin-right: 15px;
}

.icon-right {
    margin-left: 15px;
}

.center-block {
    margin-bottom: 20px;
    text-align: center;
}

.margin-bottom-5{
    margin-bottom: 5px;
}

.opacity-100{
    opacity: 100% !important;
}

.padding-right-0{
    padding-right: 0;
}

/*-------------*/

input.ng-invalid:not(.ng-pristine),
textarea.ng-invalid:not(.ng-pristine)
{
    border-color: #b94a48;
    -webkit-box-shadow: 0 0 6px #f8b9b7;
    -moz-box-shadow: 0 0 6px #f8b9b7;
    box-shadow: 0 0 6px #f8b9b7;
    color: #b94a48;
}

a {
    cursor : pointer;
}

a:hover {
    text-decoration: underline;
}


.alert.alert-danger, .alert.alert-warning, .alert.alert-success{
    z-index: 999999 !important;
}

.button-icon {
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
    color: #555;
    line-height: 1;
}

.hover-for-extra-info .extra-info {
    font-size: 12px;
    opacity: 0.4;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
}

.hover-for-extra-info:hover .extra-info {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

.preserve-formatting {
    white-space: pre-wrap;
}

.striped {
    background-image: url('/images/striped.png');
}

/*
BOOTSTRAP FIXES
*/

.fa-sm {
    font-size: 0.8em;
}

/*https://github.com/twbs/bootstrap/issues/4062*/
.dl-horizontal > dd:after {
    display: table;
    content: "";
    clear: both;
}

abbr[title] {
    border-bottom: none;
}

.nav-list {
    font-size: 12px;
}

.nav-list > .nav-header {
    font-weight: bold;
    text-transform: uppercase;
    color: #888;
    padding: 8px 10px;
}

.nav-list > li.active > a {
    font-weight: bold;
    color:#FFFFFF;
    background-color: #428bca;
}

.nav-list > li > a {
    padding: 8px 10px;
}

.well, .well-sm {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.form-control-inline {
    width: auto;
    display: inline-block;
    margin-right: 20px;
}

.form-text-inline {
    display: inline-block;
    margin-right: 20px;
    line-height: 34px;
}

.form-horizontal .control-label {
    text-align: left;
}

.input-short {
    width: 70px;
}

.alert {
    padding-right: 30px;
}

.alert-warning {
    color: #c07c01;
    background: #f9edbe;
    border: 1px solid #f0c36d;
}

@media (max-width:767px) {
    .alert {
        top: auto !important;
        bottom: 20px !important;
    }
}

.pull-right-over {
    position: absolute;
    right: 0;
    background-color: #FFF;
}

.legend-sm {
    font-size: 16px;
}

.badge {
    border-radius: 10px !important;
}

.badge-danger {
    background-color: #d9534f !important;
}


.badge-rectangle {
    border-radius: 2px !important;
    font-size: 10px;
    padding: 3px;
    line-height: 10px;
    font-weight: normal;
    vertical-align: text-bottom;
    min-width: 16px;
    background-color: #777;
    box-sizing: border-box;
}

.btn-default .badge-rectangle {
    background-color: #777;
}

.badge-rectangle-md {
    width: 20px;
}

.caret-sm {
    margin-left: 1px;
    border-top: 3px dashed;
    border-right: 3px solid transparent;
    border-left: 2px solid transparent;
}

/*
LOGIN
*/

.form-signin.form-signup {
    max-width: 600px;
    margin: 0 auto;
}

.form-signin.form-signup .input-group-addon {
    min-width: 150px;
}

.form-signin.form-signup .input-group{
    margin-bottom: 10px;
}

.form-signin {
    max-width: 380px;
    margin: 0 auto;
}

.form-signin .form-signin-heading, .form-signin .help-block {
    margin-bottom: 10px;
}

.form-signin-heading-container{
    float:left;
}

.form-signin .checkbox {
    font-size: 12px;
}

.form-signin .info-header {
    font-size: 12px;
    font-weight: bold;
}

.form-signin .checkbox {
    padding-left: 20px;
}

.div-talen-switcher{
    direction: rtl;
}

.form-signin .checkbox input[type="checkbox"] {
    margin: 2px 0 0 -20px;
}

.form-signin-container {
    padding: 10px;
    border: 1px solid #CCC;
    background-color: #eaeaea;
    border-radius: 6px;
    clear:both;
}

.form-signin-top-group input,
.form-signin-top-group span
{
   border-bottom-right-radius: 0;
   border-bottom-left-radius: 0;
}

.form-signin-bottom-group input,
.form-signin-bottom-group span
{
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

.form-signin-body {
    margin-bottom: 10px;
}

.form-signin-footer {
    margin: auto -10px -10px -10px;
    /*border-top: 1px solid #ccc;*/
    padding: 10px;
}

.form-signin .form-control {
    position: relative;
    font-size: 16px;
    height: auto;
    padding: 10px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.form-signin .input-group-addon {
    min-width: 100px;
    font-size: 12px;
    font-weight: bold;
}

.form-signin .form-control:focus {
    z-index: 2;
}

.form-signin .form-signin-top-group {
    margin-bottom: -1px;
}

.form-signin-change-language-container .selected-language{
    font-weight: bold;
    text-decoration: underline;
}

/*
FIXED AGENDA HEADER
*/

div.container-fluid{
    padding-right: 10px;
}

form.form-horizontal .form-group{
    margin-right: -10px;
}

.agenda-header {
    position: fixed;
    top: 60px;
    margin-right: 10px;
    background-color: #FFFFFF;
    margin-top: -10px;
    padding-top: 10px;
    z-index: 800;
}

.agenda-header-pusher .agenda-header {
    position: static;
    margin-right: 0;
}

/*on phone sizes header should not be fixed*/
@media (max-width:767px) {
    .agenda-header {
        position: static;
        margin-right: 0;
    }

    .agenda-header-pusher {
        display: none;
    }

    .agenda-body {
        margin: 0 !important;
    }
}

/*
AGENDAS
*/

.agenda {
    position: relative;
}

.agenda-table {
    table-layout: fixed;
    width: 100%;
}

.agenda-table td {
    padding: 0;
}

.agenda-header-uren, .agenda-dagen-y-axis {
    width: 40px;
}


.agenda-dagen-y-axis.left-border .agenda-dag-uur-label{
    left:5px;
}

.agenda-dagen-y-axis.left-border .timeline-pill{
    left:-1px;
}

.agenda-header-dagen {
    font-weight: bold;
    text-align: center;
    overflow: hidden;
}

.agenda-header-dag-indicatie {
    font-size: 12px;
    color: #666
}

.agenda-header-dag-link {
    cursor: pointer;
}

.agenda-dag-uur {
    position: relative;
}

.agenda-dag-uur-label {
    position: absolute;
    right: 5px;
    font-size: 12px;
}

/*http://stackoverflow.com/questions/5148041/does-firefox-support-position-relative-on-table-elements*/
.agenda-dag, .agenda-dagen-y-axis, .wrapper-for-position-absolute-in-table-cell-for-firefox {
    position: relative;
}


/*
AGENDA-EVENT-INFO
*/

.agenda-event-info {
    display: inline-block;
}

.agenda-event-info.agenda-event-info-left {
    margin-right: 5px;
}

.agenda-event-info.agenda-event-info-right {
    position: absolute;
    right: 10px;
}

/*
AGENDA-EVENT
*/

.agenda-event-container {
    position: absolute;
    width: 100%;
}

.agenda-event {
    font-size: 12px;
    padding: 0 0.7em;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    white-space: nowrap;
    border: 1px solid #FFFFFF;
    overflow: hidden;
    cursor: pointer;
    min-height: 10px;
    height: 100%;
}

.agenda-event.bezet {
    cursor: default;
}

.agenda-event.bezet:hover .agenda-event-content {
    text-decoration: none;
}

.agenda-event:hover .agenda-event-content {
    text-decoration: underline;
}

.agenda-event-mask-on-hover:hover {
    opacity: 0.5;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
}

.move-target {
    z-index: 100;
    opacity: 0.5;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    background-color: #ccc;
}

.agenda-event-volzet, .agenda-event-geen-afspraken {
    position: absolute;
    top: 0;
    padding-top: 15px;
    height: 100%;
    width: 100%;
    background-color: #ffffff;
    border-color: #ffffff;
    opacity: 0.7;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
    cursor: auto;
}

.agenda-event-geannuleerd {
    opacity: 0.7;
}

.agenda-event-geannuleerd .agenda-event-content {
    text-decoration: line-through;
}

/* show caret on hover when vrijgave can be configured */

.agenda-event-configure-vrijgave {
    display:none;
}

.agenda-event:hover .agenda-event-configure-vrijgave {
    display:inline-block;
}

.agenda-event:hover .werkschema-release-rectangles{
    display:none;
}

/* hide volgnummer of dagzelf when vrijgave can be configured */

.agenda-event:hover .agenda-event-vrijgave-aanpasbaar .agenda-event-vrijgave-info {
    display:none;
}

/*
AGENDA-EVENT-LABEL (DRIEHOEKJE)
*/
.agenda-event-label {
    position: absolute;
    width: 0;
    height: 0;
}

.agenda-event-label-left {
    left: 0;
    right: auto;
    border-right: 17px solid transparent;
    border-left: none;
}

.agenda-event-label-right {
    left: auto;
    right: 0;
    border-right: none;
    border-left: 17px solid transparent;
}

.agenda-event-label-top {
    top: 0;
    border-top: 17px solid #5bc0de;
}

.agenda-event-label-bottom {
    bottom: 0;
    border-bottom: 17px solid #5bc0de;
}

.agenda-event-label p {
    color: #FFFFFF;
    position: absolute;
    font-size: 8px;
    line-height: 27px;
}

.agenda-event-label-top p {
    top: -25px;
}

.agenda-event-label-bottom p {

}

.agenda-event-label-left p {
    left: 2px;
}

.agenda-event-label-right p {
    left: -7px;
}

.agenda-event-content {
    display: inline-block;
}

/*
AGENDA BORDERS
*/

.agenda-body, .agenda-header-title {
    border: 1px solid #CCC;
}

.agenda-header-title {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.agenda-body {
    border-top: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.agenda-header-dagen {
    border-left: 1px solid #CCCCCC;
    border-right: 1px solid #CCCCCC;
}

.agenda-body-dagen{
    border-left: 1px solid #CCCCCC;
    border-right: 1px solid #CCCCCC;
}

.agenda-header-dagen.hide-left-border,
.agenda-body-dagen.hide-left-border
{
    border-left: 0;
}

.agenda-header-dagen.hide-right-border,
.agenda-body-dagen.hide-right-border {
    border-right: 0;
}


td.hide-left-border .agenda-dag{
    border-left: 0;
}

.agenda-dag-uur-division {
    border-bottom: 1px dotted #CCCCCC;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

.agenda-dag-uur-division-mask-on-hover:hover {
    background-color: #ccc;
    opacity: 0.5;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
}

.agenda-dag-uur .agenda-dag-uur-division:last-child {
    border-bottom: 1px solid #CCCCCC;
}

.agenda-dagen-y-axis .agenda-dag-uur:last-child .agenda-dag-uur-division:last-child,
.agenda-dag .agenda-dag-uur:last-child .agenda-dag-uur-division:last-child {
    border-bottom: none;
}

/*
AGENDA EXPAND
*/

.agenda-body {
    position: relative;
}

.agenda-expand {
    position: absolute;
    left: 0;
    right: 0;
    background-color: #ccc;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    text-align: center;
    z-index: 700;
    cursor: pointer;
}

.agenda-expand:hover {
    opacity: 0.6;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
}

.agenda-expand-up {
    top: 0;
}

.agenda-expand-down {
    bottom: 0;
}

/*
AGENDA TOOLTIP
*/
.tooltip {
    font-size: 11px;
}

.tooltip.in {
    opacity: 1;
}

.tooltip.top .tooltip-arrow {
    border-top-color: #777;
}

.tooltip.right .tooltip-arrow {
    border-right-color: #777;
}

.tooltip.bottom .tooltip-arrow {
    border-bottom-color: #777;
}

.tooltip.left .tooltip-arrow {
    border-left-color: #777;
}

.tooltip .tooltip-inner {
    background-color: #777;
}

/*
SLICE
*/

.current-day {
    background-color: #f5f5f5;
}

/*
DATEPICKER
*/

div.ui-datepicker-inline {
    background-color: transparent;
    border: none;
    padding: 0;
}

.ui-datepicker {
    font-size: 11px;
    display: none;
    background-color: white;
    padding: 20px;
    border: 1px solid #CCCCCC;
    text-align: center;
    z-index: 30000 !important;
}

.ui-datepicker-header {
    position: relative;
}

.ui-datepicker-title {
    text-transform: capitalize;
    font-weight: bold;
}

.ui-datepicker-next, .ui-datepicker-prev {
    position: absolute;
    cursor: pointer;
}

.ui-datepicker-prev {
    left: 0;
    padding-right: 15px;
}

.ui-datepicker-next {
    right: 0;
    padding-left: 15px;
}

.ui-datepicker-next:hover, .ui-datepicker-prev:hover {
    text-decoration: none;
}

.ui-datepicker-calendar {
    border-collapse: separate;
}

.ui-datepicker-calendar thead th {
    text-align: center;
}

.ui-datepicker-calendar td {
    border: 2px solid transparent;
    cursor: pointer;
    padding: 2px;
}

.ui-datepicker-calendar td.ui-datepicker-today {
    border: 2px solid #ff7f6e;
}

.ui-datepicker-current-day, .ui-datepicker-calendar td:hover, .ui-date-picker-hover-day {
    background-color: rgb(69, 150, 211);
}

.ui-datepicker-current-day a, .ui-datepicker-calendar td:hover a, .ui-date-picker-hover-day a {
    color: #FFFFFF;
    text-decoration: none;
}

.ui-datepicker-calendar td.ui-datepicker-unselectable, .ui-date-picker-hover-day.ui-datepicker-unselectable {
    background-color: inherit;
}

.full-width-datepicker .ui-datepicker-calendar {
    width: 100%;
}

/*
LEGENDE
*/

.legende-item {
    margin-bottom: 4px;
    padding: 8px 10px;
    border-radius: 4px;
    text-overflow: ellipsis;
}

.legende-item.active {
    text-decoration: underline;
}

.legende .legende-item:last-child {
    margin-bottom: 0;
}

.nav-list li.agenda-legend-item a{
    margin-left: -10px;
    margin-right: -10px;
    padding-left: 20px;
}

/*
LOADING WIDGET
*/
.loading-widget {
    position: absolute;
    top: 0;
    right: 15px;
    z-index: 2000;
}

.loading-widget p {
    font-size: 11px;
    padding: 4px 8px;
    display: inline;
}

/*
POPOVER
*/

.popover {
    z-index: 3000;
}

.popover .popover-action {
    text-decoration: underline;
    margin-left: 10px;
}

.popover .popover-action:first-child {
    margin-left: auto;
}

.popover-content-sm {
    width: 120px;
}

.popover-content-md {
    width: 240px;
}

.popover-content-260 {
    width: 260px;
}

.popover-max-width-500{
    max-width: 500px;
}


.popover-title {
    margin: -10px -15px 15px;
}

.popover-text-label {
    color: #999;
    display: block;
}

.popover-input-label {
    color: #999;
    display: block;
    padding-bottom: 5px;
}

.popover-quote {
    font-size: 12px;
    text-align: right;
    color: #999
}

.popover-footer {
    padding: 8px 14px;
    font-size: 14px;
    font-weight: normal;
    line-height: 18px;
    background-color: #f7f7f7;
    border-top: 1px solid #ebebeb;
    margin: 0 -15px -10px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

.popover label{
    font-weight: normal;
}

.eventclickedpopover, .movetargetpopover, .eventvrijgaveclickedpopover, .dayclickedpopover {
    position: absolute;
}

/*
BERICHTEN
*/

.bericht {
    font-size: 12px;
    color: #666;
    position: relative;
}

.geen-berichten {
    font-size: 12px;
}

.bericht-heading .tooltip {
    white-space: nowrap;
}

.bericht-heading .show-on-hover {
    display: none;
}

.bericht-heading:hover .show-on-hover {
    display: inline-block;
}

.bericht > .bericht-heading {
    color: #666;
    background-color: transparent;
}

.bericht-afzender {
    display: inline-block;
    font-weight: bold;
}

.bericht-timestamp {
    color: #888;
    text-align: right;
    margin-bottom: 10px;
}

.bericht-ontvangers {
    list-style-type: none;
    padding: 0;
    display: inline-block;
    margin-bottom: 0;
}

.bericht-ontvangers-ontvanger {
    display: inline-block;
    margin-right: 10px;
    white-space: nowrap;
    font-weight: bold;
}


.bericht-commentaren {
    padding-left: 30px;
}

.bericht-commentaar {
    padding-top: 10px;
}

.bericht-actions {
    visibility: hidden;
    text-align: right ;
}

.bericht-body:hover .bericht-actions {
    visibility: visible;
}

.bericht-status-action {
    height: 54px;
    width: 50px;
    text-align: center;
    background-color: #fff;
    padding-top: 20px;
    cursor: pointer;
    color: #888;

}

.bericht-status-action-left {
    float: left;
    border-right: 1px solid #ddd;
    margin: -10px 10px -10px -10px;
}

.bericht-status-action-right {
    float: right;
    margin: -10px -10px -10px 10px;
    border-left: 1px solid #ddd;
}

.highlight {
    padding: 2px 0 2px 0;
    background-color: #ffff00;
}

/*
AFWERKBAAR BERICHT
*/

.bericht-ontvangers-ontvanger-heeft-uitgevoerd {
    text-decoration: line-through;
}

/*
BERICHT ANIMATIE
*/

.animate-bericht.ng-enter {
    -moz-animation: grow 0.4s, show 0.4s;
    -moz-animation-timing-function: ease-out;
    -moz-animation-iteration-count: 1;
    -o-animation: grow 0.4s, show 0.4s;
    -o-animation-timing-function: ease-out;
    -o-animation-iteration-count: 1;
    -webkit-animation: grow 0.4s, show 0.4s;
    -webkit-animation-timing-function: ease-out;
    -webkit-animation-iteration-count: 1;
    animation: grow 0.4s, show 0.4s;
    animation-timing-function: ease-out;
    animation-iteration-count: 1;
    overflow: hidden;
}

.animate-bericht.ng-leave {
    -moz-animation: hide 0.4s, shrink 0.4s;
    -moz-animation-timing-function: ease-out;
    -moz-animation-iteration-count: 1;
    -o-animation: hide 0.4s, shrink 0.4s;
    -o-animation-timing-function: ease-out;
    -o-animation-iteration-count: 1;
    -webkit-animation: hide 0.4s, shrink 0.4s;
    -webkit-animation-timing-function: ease-out;
    -webkit-animation-iteration-count: 1;
    animation: hide 0.4s, shrink 0.4s;
    animation-timing-function: ease-out;
    animation-iteration-count: 1;
    overflow: hidden;
}

@-webkit-keyframes shrink
{
    from { max-height: 2000px } to { max-height: 0; }
}

@-webkit-keyframes hide
{
    from { opacity: 1 } to { opacity: 0 }
}

@-webkit-keyframes grow
{
    from { max-height: 0 } to { max-height: 2000px; }
}

@-webkit-keyframes show
{
    from { opacity: 0 } to { opacity: 1 }
}

@-moz-keyframes shrink
{
    from { max-height: 2000px } to { max-height: 0; }
}

@-moz-keyframes hide
{
    from { opacity: 1 } to { opacity: 0 }
}

@-moz-keyframes grow
{
    from { max-height: 0 } to { max-height: 2000px; }
}

@-moz-keyframes show
{
    from { opacity: 0 } to { opacity: 1 }
}

@-o-keyframes shrink
{
    from { max-height: 2000px } to { max-height: 0; }
}

@-o-keyframes hide
{
    from { opacity: 1 } to { opacity: 0 }
}

@-o-keyframes grow
{
    from { max-height: 0 } to { max-height: 2000px; }
}

@-o-keyframes show
{
    from { opacity: 0 } to { opacity: 1 }
}

@keyframes shrink
{
    from { max-height: 2000px } to { max-height: 0; }
}

@keyframes hide
{
    from { opacity: 1 } to { opacity: 0 }
}

@keyframes grow
{
    from { max-height: 0 } to { max-height: 2000px; }
}

@keyframes show
{
    from { opacity: 0 } to { opacity: 1 }
}


/*
TIMELINE
*/

.timeline {
    position: absolute;
    width: 100%;
    z-index: 600
}

.timeline-ruler {
    margin:0;
    border: 1px solid #ff7f6e;
}

.timeline-pill {
    right: -1px;
    width: auto;
    font-size: 12px
}

.timeline-pill .label-danger {
    background-color: #FF5C43;
}

/*
STICKY FOOTER
*/

.footer {
    height: 60px;
    background-color: #f5f5f5;
    border-top: 1px solid #E3E3E3;
    margin-top: 20px;
    padding-top: 20px;
    font-size: 12px;
    z-index: 1030;
    position: relative; /* fix zodat footer over sidebar komt http://stackoverflow.com/questions/5218927/z-index-not-working-with-fixed-positioning */
}

.wrapper-for-sticky-footer {
    min-height: 100%;
    height: auto;
    margin: 0 auto -80px;
    padding: 0 0 80px;
}

html, body {
    height: 100% !important;
}

/*
PAGE WITH SIDEBAR
*/

.page-with-sidebar {
    padding-left: 235px;
}

.page-with-sidebar.large-margin .page-content-wrapper {
    padding: 20px;
}

.sidebar-wrapper {
    margin-left: -250px;
    left: 250px;
    width: 250px;
    position: fixed;
    top: 50px;
    bottom: 0;
    overflow-y: auto;
    z-index: 1000;
    padding: 10px 15px 0;
    background-color: #ffffff;
    transition: all 0.4s ease 0s;
    margin-bottom: 50px;
}

.page-with-sidebar.large-margin .sidebar-wrapper {
    margin-left: -250px;
    left: 250px;
    width: 250px;
    top: 50px;
    bottom: 0;
    overflow-y: auto;
    z-index: 1000;
    padding: 10px 15px 0;
    background-color: #ffffff;
    float:left;
    margin-bottom: 0;
    transition: all 0.4s ease 0s;
    position: static;
}

.page-content-wrapper {
    padding: 0;

}

@media (max-width:767px) {

    .page-with-sidebar {
        position: relative;
        left: 0;
        padding-left: 0;
        transition: all 0.4s ease 0s;
    }

    .page-with-sidebar.large-margin .sidebar-wrapper{
        float:none;
        position: fixed;
        margin-bottom: 50px;
    }

    .sidebar-wrapper,
    .page-with-sidebar.large-margin .sidebar-wrapper{
        left: 0;
    }

    .page-with-sidebar.active {
        left: 250px;
    }

    .page-with-sidebar.active .sidebar-wrapper,
    .page-with-sidebar.active.large-margin .sidebar-wrapper{
        left: 250px;
        width: 250px;
    }

}

.sidebar-expand-button {
    width: 44px;
    top: 0;
    left: 0;
    z-index: 1040;
    position: fixed;
    margin-left: 15px;
    padding: 9px 10px;
    margin-top: 8px;
    margin-bottom: 8px;
    border: 1px solid #ddd;
    background-color: #ccc;
}

.sidebar-expand-button:hover {
    background-color: #ddd;
}

.small-column {
    max-width: 600px;
}

.medium-column {
    max-width: 800px;
}


/*
COLORPICKER
*/

.colorpicker-button {
    width: 70px;
}

.colorpicker-button .caret {
    margin-left: 35px;
}

.colorpicker-button.disabled .caret {
    visibility: hidden;
}

.colorpicker-colors {
    padding: 4px;
    min-width: 130px;
    max-width: 130px;
}

.colorpicker-color {
    display: block;
    float: left;
    width: 20px;
    height: 20px;
    margin: 2px;
}

.colorpicker-color > a {
    display: block;
    width: 20px;
    height: 20px;
    margin: 0;
    padding: 0 !important;
    border-radius: 0;
    position: relative;
    -webkit-transition: all ease 0.1s;
    transition: all ease 0.1s;
    color: #fff !important;
    line-height: 20px !important;
    font-size: 11px;
    text-align: center;
}

.colorpicker-color > a:hover {
    text-decoration: none;
    opacity: 0.8;
    filter: alpha(opacity = 80);
    -webkit-transform: scale(1.08);
    -ms-transform: scale(1.08);
    transform: scale(1.08);
}

.colorpicker-color > a.selected:after {
    content: "\e013";
    font-family: 'Glyphicons Halflings';
    display: inline-block;
    font-size: 11px;
    color: #FFF;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    line-height: 20px;
}

/*
VRIJGAVESELECTOR
*/

.vrijgaveselector {
}

.vrijgaveselector .btn {
    text-align: left;
}

.vrijgaveselector .btn > .badge {
    top: 2px;
}

.vrijgaveselector .vrijgave-in-volgorde .badge {
    margin: 0 2px;
    border: 1px solid transparent;
    height: 18px;
}

/*
NAVBAR
*/

.navbar-default {
    background-color: #2d85c3;
}

.navbar-default .navbar-brand, .navbar-default .navbar-nav>li>a {
    color: #ffffff;
}

.navbar-default .navbar-brand:hover, .navbar-default .navbar-brand:focus {
    color: #ffffff;
}

.navbar-default .navbar-nav>li>a:hover, .navbar-default .navbar-nav>li>a:focus, .navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:hover, .navbar-default .navbar-nav>.active>a:focus {
    color: #ffffff;
    background-color: #285e8e;
}

.navbar-default .navbar-nav>.open>a, .navbar-default .navbar-nav>.open>a:hover, .navbar-default .navbar-nav>.open>a:focus {
    color: #ffffff;
    background-color: #285e8e;
}

.navbar-default .navbar-toggle {
    background-color: #ccc;
}

.sidebar-expand-button {
    background-color: #ccc;
}

@media (max-width: 767px) {
    .navbar-default .navbar-nav .open .dropdown-menu > li > a {
        color: #ffffff;
    }

    .navbar-default .navbar-nav .open .dropdown-menu>li>a:hover, .navbar-default .navbar-nav .open .dropdown-menu>li>a:focus {
        color: #ffffff;
        background-color: #285e8e;
    }

    .navbar-nav .open .dropdown-menu>li>a, .navbar-nav .open .dropdown-menu .dropdown-header {
        color: #ffffff;
    }
}
@media (max-width: 768px) {
    .navbar-agendaNaam {
        margin-left: -80px;
        position: relative;
        width: 100%;
        padding-left: 160px;
        text-align: center;
        font-size: 22px;
    }
}

@media (min-width: 768px){
    .navbar-agendaNaam {
        display: none!important;
    }
}

/*
ZOEKEN
*/

.search {
    position: relative;
}

.search .dropdown-menu {
    padding: 0;
    width: 300px;
    max-height: 400px;
    overflow-y: auto;
}

.search .search-result-item {
    border-bottom: 1px solid #ddd;
    overflow-x: hidden;
}

.search .search-result-item-heading {
}

.search-result-geannuleerd {
    text-decoration: line-through;
}

/*
HELP
*/

.infoblock {
    margin-bottom: 30px;
}

.nav.nav-tabs {
    margin-bottom: 20px;
}

/*
DISABLED SPAN
 */

span.spanDisabled{
    opacity: 0.5;
    pointer-events: none;
}

/*
PATIENTBHEER
 */


.patientinfo-content .modal-body, .patientinfoaanpassen-content .modal-body{
    padding:0;
}

.patientinfo-content .modal-margin,
.patientinfoaanpassen-content .modal-margin{
    padding:20px;
}


td.patientinfo-label {
    font-weight: bold;
    font-size: 18px;
    padding: 5px 20px 5px 15px;
    opacity: .7;
    width: 35px;
    vertical-align: top;
}

.patientinfo-label i.fa{
    text-align: center;
    display: inline-block;
    width: 100%;
}


.patientinfo-content .modal-header:after,
.patientinfoaanpassen-content .modal-header:after {
    content: "";
    display: block;
    margin: 0 auto;
    padding-top: 15px;
    border-bottom: 1px solid #2d85c3;
}

.patientinfo-content HR,
.patientinfoaanpassen-content HR{
    margin: 0;
}

.patientinfo-content .modal-header,
.patientinfoaanpassen-content .modal-header{
    padding-bottom: 0;
    border-bottom: 0;
}

.patientinfoaanpassen-content .form-signin .form-control {
    font-size: 14px;
}

/* MODAL */
div.modal-content{
    border-radius: 10px !important;
}

.modal-header {
    padding: 20px;
}

.modal-body{
    padding:20px;
}

.modal-footer {
    padding: 10px 15px;
}

.modal-last-form-group.form-group{
    margin-bottom: 0;
}

.modal-info{
    color: white;
    background-color: #428bca;
    padding: 10px 20px 10px 20px;
    border-bottom: 1px solid #e5e5e5;
    position: relative;
}

.modal-info-icon{
    top: 11px;
    position: absolute;
}

.modal-info-text{
    position: relative;
    left:30px;
    padding-right: 30px;
}

.instellingen-week-switch td{
    text-align: center;
    width:100px;
    padding-bottom: 5px;
}

@media (min-width: 768px) {
    .form-width-large-screen{
       width: 122px;
    }
}


hr.modal-body-full-width-hr{
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: -20px;
    margin-right: -20px;
}

.icon-on-button{
    margin-right: 5px;
}

.md-tab{
    color: white;
}

.disabled {
    color: grey !important;
    opacity:0.6;
    pointer-events:none;
}



.borderlesstd {
    border: none;
    border-top-width: 0 !important;
}

.datestepper-agenda{
    margin-bottom: 10px;
}



@media (max-width: 600px) {
    .datestepper-agenda {
        text-align: center;
        float: none !important;
    }

    .agenda-navigator-div {
        text-align: center;
        position: relative;
        margin-bottom: 10px;
    }

    .agenda-header-title-date {
        text-align: center;
        margin-left: 0;
        float: none !important;
    }
}



/* SIDEBAR INFO */

@media (min-width: 768px) {
    .page-with-sidebar-info-content-container{
        padding-top: 20px;
        padding-left: 25px;
    }
}



@media (max-width:767px) {
    .page-with-sidebar-info{
        padding-left: 0 !important;
    }

    .sidebar-info-container{
        display: none !important;
    }
}

.page-with-sidebar-info{
    padding-left: 300px;
}

.sidebar-info-container{
    margin-left: -300px;
    left: 300px;
    width: 300px;
    position: fixed;
    padding: 25px;
    background: #2d85c3;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    color: white;
    margin-top: 25px;
    box-shadow: 0 6px 12px rgb(0 0 0 / 36%);
}

.sidebar-info-icon-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.sidebar-info-title{
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 5px;
}

.sidebar-info-row{
    margin-bottom: 5px;
}

.sidebar-info-icon{
    width: 25px;
    display: inline-block;
    vertical-align: top;
}

.sidebar-info-text,
.sidebar-info-text-multiline{
    width: 200px;
    display: inline-block;
    word-wrap: break-word;
}

/* AGENDA HOVER EFFECT https://www.trysmudford.com/blog/fade-out-siblings-css-trick/ */

.agenda-body.appointment-multi-select:hover  .simple-event{
    opacity: 0.5 !important;
}


.agenda-body.appointment-multi-select .simple-event{
    z-index: 1;
    transition: opacity .2s ease-in-out;
    -moz-transition: opacity .2s ease-in-out;
    -webkit-transition: opacity .2s  ease-in-out;
}

.agenda-body.appointment-multi-select .simple-event-base{
    z-index: 1;
    pointer-events: auto;
}

.agenda-body.appointment-multi-select{
    pointer-events: none;
}

.agenda-body.appointment-multi-select:hover .simple-event:hover {
    opacity: 1 !important;
    z-index: 2;
}

.simple-event-sub {
    display: none;
    pointer-events: none;
}

.agenda-body.appointment-multi-select:hover  .simple-event:hover .simple-event-sub {
    display: block;
}



/* TOOLTIP */
.tooltip.doclr-tooltip-style .tooltip-inner {
    color: white;
    background: #428bca;
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 12px;
}


.tooltip.doclr-tooltip-style.white-background   .tooltip-inner{
    color: black;
    background: white;
    border-color: #428bca;
    border-style: solid;
    border-width: thin;
}

.tooltip.top.doclr-tooltip-style  .tooltip-arrow {
    border-top-color: #428bca !important;
}

.tooltip.bottom.doclr-tooltip-style  .tooltip-arrow {
    border-bottom-color: #428bca !important;
}

.doclr-tooltip-style.width-200{
    width:200px
}
.doclr-tooltip-style.width-500{
    width:500px;

}

.doclr-tooltip-style.width-500 .tooltip-inner{
    max-width:500px!important;
}

.doclr-tooltip-style.align-left .tooltip-inner{
    text-align: left !important;
}


tooltip-popup-close-delay

.tooltip.doclr-tooltip-style-red .tooltip-inner {
    color: white;
    background: #d9534f;
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 12px;
}


.tooltip.top.doclr-tooltip-style-red  .tooltip-arrow {
    border-top-color: #d9534f !important;
}

.color-doclr-red{
   color: #d9534f;
}

.dropdown-menu>li>a:hover{
    background-color: #428bca;
    color: white;
}


.right-to-left{
    direction: rtl;
}

.left-to-right{
    direction: ltr;
}


.legende-item .icon-container{
    position: absolute;
    top: 0;
    right: 0;
}


.legende-item .text-container{
    text-overflow: ellipsis;
    overflow: hidden;
}

.display-none {
    display: none;
}


.doclr-color-red{
    color: #d9534f;
}

.block-list-container .blocklist-list-item:not(:last-child){
    margin-bottom: 12px;
    border-bottom: 1px dashed #eee;
}






/*
 PIVOT
 */


table.pvtTable tbody tr td,
table.pvtTable thead tr th,
table.pvtTable tbody tr th {
    border-color: #e4e4e4 !important;
}

.pvtAxisContainer, .pvtVals {
    background-color: #f7f7f7 !important;
    border: 1px solid #e4e4e4 !important;
}

.pvtAxisContainer.pvtHorizList.pvtCols.pvtUiCell.ui-sortable {
    border-bottom-style: none !important;
}

.pvtAxisContainer.pvtRows.pvtUiCell.ui-sortable {
    border-right-style: none !important;
}

.pvtRendererArea {
    padding: 0px !important;
}

table.pvtTable tbody tr th, table.pvtTable thead tr th {
    background-color: #FFFFFF !important;
    font-size: 12px !important;
}

table.pvtTable {
    margin-right: -1px !important;
    margin-bottom: -1px !important;
}

table.pvtTable tbody tr td.pvtColSubtotal, table.pvtTable tbody tr td.pvtRowSubtotal {
    background-color: #f7f7f7 !important;
    color: #428bca !important;
}

.pvtFilterBox p button {
    color: #fff;
    background-color: #428bca;
    padding: 6px 12px;
    margin-left: 5px;
    font-size: 14px;
    font-weight: 400;
    border: 1px solid transparent;
}

.pvtFilterBox p button:hover {
    color: #fff;
    background-color: #286090;
    border-color: #204d74;
}

.pvtCheckContainer {
    overflow: auto !important;
}


.pvtGrandTotal {
    background-color: #428bca !important;
    color: white !important;
}

.pvtAttr {
    color: #fff !important;
    background-color: #428bca !important;
    padding: 6px 12px !important;
    font-size: 12px !important;
    margin-left: 5px !important;
}

.pvtAttr .pvtTriangle {
    color: #fff !important;
}

.pvtVals {
    border-top-style: none !important;
}

.pvtUnused {
    border-left-style: none !important;
}

.pvtColOrder, .pvtRowOrder, .pvtAggregator {
    display: none !important;
}

select.pvtRenderer {
    height: 34px;
    border-color: #e4e4e4;
    width: 100%;
    color: #333;
}


.able.pvtTable {
    font-size: 12px !important;
}

.pvtTable tbody tr:hover td.pvtVal,
.pvtTable tbody tr:hover td.pvtTotal,
.pvtTable tbody tr:hover th {
    background-color: #428bca !important;
    color: #fff !important;

}

.hide-settings table tbody tr td.pvtAxisContainer,
.hide-settings table tbody tr td.pvtVals.pvtUiCell {
    display: none;
}

table.pvtUi,
table.pvtTable {
    width: 100%;
}

.gtitle,
.legendtext,
.xtick text,
.ytitle {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

div.plot-container.plotly {
    border-color: #e4e4e4;
    border-style: solid;
    border-width: thin;
}

.btn-primary {
    background-color: #428bca;
}

.modebar {
    display: none;
}

g.slice path{
    stroke-width: 2px !important;
    stroke: white !important;
}

g.pielayer {
    -webkit-filter: drop-shadow( 4x 10px 7px rgba(0, 0, 0, .2)) !important;
    filter: drop-shadow( 4px 10px 7px rgba(0, 0, 0, 0.2)) !important;
}

.pivot-geen-afspraken{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 16px;
    transition: margin-top .2s ease-in-out;
}


/*
 ---------
 */

.icon-background {
    color: #efefef;
}


/*
 PATIENT INFO
 */

.patient-appointment-info-panel{
    margin-left: 10px;
    margin-right: 10px;
}

/* ----------- PATIENT INFO ---------- */

.geen-afspraak-mogelijkheden{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 16px;
    transition: margin-top .2s ease-in-out;
}


td.table-td-pointer{
    cursor: pointer;
}

td.table-td-pointer{
    cursor: pointer;
}

table.table-no-first-border tr:first-child td{
    border-top:0;
}

.full-width-table-container.px20{
    margin-right: -20px;
    margin-left: -20px;
}

.full-width-table-container table tr td:first-child{
    padding-left: 20px;
}

.full-width-table-container table tr td:last-child{
    padding-right: 20px;
}


/* ----------- Higlight event ---------- */

.agenda-body .agenda-event{
    transition: opacity .5s ease-in-out;
    -moz-transition: opacity .5s ease-in-out;
    -webkit-transition: opacity .5s ease-in-out;
}

.highlighted-agenda .agenda-event{
    opacity: 40%;
}

.highlighted-agenda .agenda-event.highlighted-event{
    opacity: 100%;
}
/* ------------------------------------- */


/*-----------------------SMS MANAGEMENT-------------*/
.sms-management-table td{
    padding: 5px;
}

.sms-management-table {
 /*   width: 100%; */
}

.sms-management-table span.no-sms{
    color: lightgrey;
}

.sms-management-table td:first-child {
    max-width: 200px;
    word-break: break-all;
}

.sms-management-table .type-overflow{
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
}

.praktijken-dropdown-menu{
    height: 600px;
    overflow-y: auto;
}


/* loadingmask */
.lmask {
    position: fixed;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.2);
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9999;
    opacity: 1;
}

.lmask.fixed {
    position: fixed;
}

.lmask:before {
    content: '';
    background-color: rgba(0, 0, 0, 0);
    border: 5px solid #2187e7;
    border-right: 5px solid rgba(0, 0, 0, 0);
    border-left: 5px solid rgba(0, 0, 0, 0);
    border-radius: 50px;
    box-shadow: 0 0 35px #2187e7;
    width: 50px;
    height: 50px;
    -moz-animation: spinPulse 1s infinite ease-in-out;
    -webkit-animation: spinPulse 1s infinite linear;

    margin: -25px 0 0 -25px;
    position: absolute;
    top: 50%;
    left: 50%;
}

.lmask:after {
    content: '';
    background-color: rgba(0, 0, 0, 0);
    border: 5px solid #428bca;
    opacity: .9;
    border-left: 5px solid rgba(0, 0, 0, 0);
    border-right: 5px solid rgba(0, 0, 0, 0);
    border-radius: 50px;
    box-shadow: 0 0 15px #428bca;
    width: 30px;
    height: 30px;
    -moz-animation: spinoffPulse 1s infinite linear;
    -webkit-animation: spinoffPulse 1s infinite linear;

    margin: -15px 0 0 -15px;
    position: absolute;
    top: 50%;
    left: 50%;
}


@-moz-keyframes spinPulse {
    0% {
        -moz-transform: rotate(160deg);
        opacity: 0;
        box-shadow: 0 0 1px #428bca;
    }
    50% {
        -moz-transform: rotate(145deg);
        opacity: 1;
    }
    100% {
        -moz-transform: rotate(-320deg);
        opacity: 0;
    }
}

@-moz-keyframes spinoffPulse {
    0% {
        -moz-transform: rotate(0deg);
    }
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spinPulse {
    0% {
        -webkit-transform: rotate(160deg);
        opacity: 0;
        box-shadow: 0 0 1px #428bca;
    }
    50% {
        -webkit-transform: rotate(145deg);
        opacity: 1;
    }
    100% {
        -webkit-transform: rotate(-320deg);
        opacity: 0;
    }
}

@-webkit-keyframes spinoffPulse {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

/* END loadingmask */

/* Availability Strategy */

.availabilityStrategyEdit .badge-rectangle{
    opacity: 50%;
}

.availabilityStrategyEdit .badge-rectangle:hover{
    opacity: 100%;
}

.availabilityStrategyEdit .badge-rectangle.active{
    opacity: 100%;
}

.availabilityStrategyEdit .release-day-container {
    display: inline-block;
    vertical-align: top;
}

.availabilityStrategyEdit .release-order-container {
    display: inline-block;
    vertical-align: top;
    margin-left: 20px;
}

.availabilityStrategyEdit .vrijgaveselector{
    margin-top:10px;
}

.availabilityStrategyEdit .btn-default.active{
    border-color: #428bca;
}


/* END Availability Strategy */
